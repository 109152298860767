(function (factory) {
  typeof define === 'function' && define.amd ? define('index', factory) :
  factory();
})((function () { 'use strict';

  // Event delegation for handling click events on 'a' tags within '.menuItems'
  document.addEventListener('click', function (event) {
    // Check if the clicked element is an 'a' tag inside '.menuItems'
    if (event.target.tagName.toLowerCase() === 'a' && event.target.closest('.menuItems')) {
      var anchor = event.target; // Make sure this.hash has a value before overriding default behavior

      if (anchor.hash !== '') {
        // Prevent default anchor click behavior
        event.preventDefault(); // Store hash

        var hash = anchor.hash; // Get the target element

        var targetElement = document.querySelector(hash); // Check if target element exists

        if (targetElement) {
          // Calculate the top offset of the target element including nav height
          var navHeight = document.querySelector('.navBar').offsetHeight; // Get nav height

          var topOffset = targetElement.offsetTop - navHeight; // var topOffset = targetElement.offsetTop;
          // Use window.scrollTo with smooth behavior for smooth scrolling

          window.scrollTo({
            top: topOffset,
            behavior: 'smooth'
          }); // Optionally add hash (#) to URL when done scrolling
          // window.setTimeout(() => {
          //     window.location.hash = hash;
          // }, 800); // The duration should match the scroll duration
        }
      }
    }
  }); // Add a scroll event listener to the window

  window.addEventListener('scroll', function () {
    // Check the current scroll position
    if (window.scrollY >= 100) {
      // this.alert(1234);
      // Add the 'fixed-header' class to the 'nav' element
      document.querySelector('.mobileHeader').classList.add('fixed-header');
    } else {
      // Remove the 'fixed-header' class from the 'nav' element
      document.querySelector('.mobileHeader').classList.remove('fixed-header'); // Remove the 'visible-title' class from the 'div' inside the 'nav' element
      //   document.querySelector('nav div').classList.remove('visible-title');
    }
  }); // Select all elements with the class 'menu'

  const menuElements = document.querySelectorAll('.menu'); // Add a click event listener to each element with the class 'menu'

  menuElements.forEach(function (menu) {
    menu.addEventListener('click', function (event) {
      const body = document.body;
      const mobileMenuElement = document.querySelector('.mobileMenu'); // Prevent the event from bubbling up to the document

      event.stopPropagation(); // Check if the clicked element already has the 'open' class

      if (this.classList.contains('open')) {
        this.classList.remove('open');
        mobileMenuElement.classList.remove('active');
        body.classList.remove('menuActive');
      } else {
        // Add the 'open' class to the clicked element
        this.classList.add('open');
        mobileMenuElement.classList.add('active');
        body.classList.add('menuActive');
      }
    });
  }); // Function to handle click events on 'a' tags within '.mobileMenu'

  document.addEventListener('click', function (event) {
    if (event.target.tagName.toLowerCase() === 'a' && event.target.closest('.mobileMenu')) {
      const mobileMenu = event.target.closest('.mobileMenu');
      mobileMenu.classList.remove('active');
      const menuItems = document.querySelectorAll('.menu');
      menuItems.forEach(function (menu) {
        menu.classList.remove('open');
      }); // Remove the menuActive class from the body

      document.body.classList.remove('menuActive');
    } else {
      // If the click is outside of .mobileMenu or .menu, close the menu
      const mobileMenuElement = document.querySelector('.mobileMenu');

      if (!event.target.closest('.menu') && !event.target.closest('.mobileMenu')) {
        mobileMenuElement.classList.remove('active');
        document.body.classList.remove('menuActive');
        const menuItems = document.querySelectorAll('.menu');
        menuItems.forEach(function (menu) {
          menu.classList.remove('open');
        });
      }
    }
  }); // Function to handle right-click events (contextmenu)

  document.addEventListener('contextmenu', function (event) {
    // If the right-click is outside of .mobileMenu or .menu, close the menu
    const mobileMenuElement = document.querySelector('.mobileMenu');

    if (!event.target.closest('.menu') && !event.target.closest('.mobileMenu')) {
      mobileMenuElement.classList.remove('active');
      document.body.classList.remove('menuActive');
      const menuItems = document.querySelectorAll('.menu');
      menuItems.forEach(function (menu) {
        menu.classList.remove('open');
      });
    }
  });
  document.addEventListener("DOMContentLoaded", function () {
    // Clone the entire navBar structure
    const navBar = document.querySelector('.navBar');
    const cloneNavBar = navBar.cloneNode(true); // Remove the .col-7 element from the cloned structure

    const col7 = cloneNavBar.querySelector('.col-7');

    if (col7) {
      col7.remove();
    } // Change .col-5 to .col-10 in the cloned structure


    const col5 = cloneNavBar.querySelector('.col-5');

    if (col5) {
      col5.classList.remove('col-5');
      col5.classList.add('col-10');
    } // Append the cloned structure to the div with class .mobileHeader


    const mobileHeader = document.querySelector('.mobileHeader');

    if (mobileHeader) {
      mobileHeader.appendChild(cloneNavBar);
    }
  }); // Function to get the height of the #home element

  function getHomeHeight() {
    const homeElement = document.getElementById('home');
    return homeElement ? homeElement.offsetHeight : 0;
  } // Function to handle scroll events


  function handleScroll() {
    const homeHeight = getHomeHeight();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const menuElements = document.querySelectorAll('.menu');
    menuElements.forEach(function (menu) {
      if (scrollTop >= homeHeight) {
        menu.classList.add('visible');
      } else {
        menu.classList.remove('visible');
      }
    });
  } // Function to handle resize events


  function handleResize() {
    handleScroll(); // Re-check scroll position after resize
  } // Add scroll event listener


  window.addEventListener('scroll', handleScroll); // Add resize event listener

  window.addEventListener('resize', handleResize); // Initial check

  handleScroll(); // Select the element with the class 'menuItems'

  const menuItems = document.querySelector('.menuItems'); // Clone the 'menuItems' element

  const clonedMenuItems = menuItems.cloneNode(true); // Select the element with the class 'mobileMenu'

  const mobileMenu = document.querySelector('.mobileMenu'); // Append the cloned 'menuItems' to the 'mobileMenu' div

  mobileMenu.appendChild(clonedMenuItems); // Slider

  document.addEventListener('DOMContentLoaded', function () {
    var splide = new Splide('.splide', {
      perPage: 5,
      gap: '2rem',
      arrows: false,
      drag: 'free',
      snap: true,
      breakpoints: {
        1200: {
          perPage: 5,
          gap: '.7rem'
        },
        1100: {
          perPage: 4,
          gap: '.7rem',
          arrows: true,
          pagination: false
        },
        900: {
          perPage: 3,
          gap: '.7rem'
        },
        700: {
          perPage: 2,
          gap: '.7rem'
        },
        500: {
          perPage: 1,
          gap: '.7rem',
          arrows: true,
          pagination: false
        }
      }
    });
    splide.mount();
  });
  jQuery(document).ready(function () {// alert('Hello World!');
  });

}));
